<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <h1>{{ $t("Gross monthly income") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "In the previous step we have tried to determine your net income. We also need to know your gross income. Please share your most recent gross income which can be found on your payslip."
              )
            }}
          </p>
          <div class="d-flex">
            <b-spinner
              class="mx-auto"
              large
              label="Spinning"
              variant="primary"
              v-if="getLoadings.get_tenant"
            >
            </b-spinner>
          </div>
          <div class="mt-3" v-if="!getErrors.tenant">
            <b-form-group v-slot="{ ariaDescribedby }" v-if="!getLoadings.get_tenant">
              <TextInput
                name=""
                type="number"
                v-model="form.personalMonthlyIncome"
                label="Gross monthly income"
                :aria-describedby="ariaDescribedby"
                :masked="true"
                :mask="[
                  {
                    mask: 'num',
                    blocks: {
                      num: {
                        mask: Number, // enable number mask
                        scale: 2,
                        signed: false,
                        thousandsSeparator: '.',
                        padFractionalZeros: false,
                        normalizeZeros: true,
                        //radix: ',',
                        min: 0,
                        max: 99999999,
                      },
                    },
                  },
                ]"
                defaultValue=""
                prepend="€"
              />
            </b-form-group>
          </div>
          <div class="alert alert-danger mb-3" v-if="getErrors.tenant">
            {{ getErrors.tenant }}
          </div>
          <div class="w-100">
            <button
              class="btn btn-primary btn-block"
              :disabled="!(form && form.personalMonthlyIncome) || getLoadings.tenant"
              @click="onSubmit"
            >
              <b-spinner
                v-if="getLoadings.tenant"
                small
                label="Spinning"
                variant="white"
                class="mr-1"
              />
              {{ $t("Next step") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <!-- <img src="../../../assets/images/expat/step8.png" alt=""> -->
          <RightPoints active="5" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";
import TextInput from "../../../components/ui/form/controls/TextInput.vue";

export default {
  data() {
    return {
      error: "",
      form: {
        personalMonthlyIncome: "",
      },
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    RightPoints,
    TextInput,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["transactions"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async created() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
      this.form.personalMonthlyIncome = this.tenant.personalMonthlyIncome;
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async onSubmit() {
      let personalMonthlyIncome = this.form.personalMonthlyIncome;
      personalMonthlyIncome = personalMonthlyIncome
        ? ("" + personalMonthlyIncome).replace(".", "")
        : personalMonthlyIncome;
      await this.updateTenant({ personalMonthlyIncome });
      this.$router.push("/user/onboarding/success");
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 450px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
