var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AuthLayoutContent',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"step d-flex align-items-center mx-auto"},[_c('div',{staticClass:"w-100"},[_c('h1',[_vm._v(_vm._s(_vm.$t("Gross monthly income")))]),_c('p',{staticClass:"fw-medium text-gray"},[_vm._v(" "+_vm._s(_vm.$t( "In the previous step we have tried to determine your net income. We also need to know your gross income. Please share your most recent gross income which can be found on your payslip." ))+" ")]),_c('div',{staticClass:"d-flex"},[(_vm.getLoadings.get_tenant)?_c('b-spinner',{staticClass:"mx-auto",attrs:{"large":"","label":"Spinning","variant":"primary"}}):_vm._e()],1),(!_vm.getErrors.tenant)?_c('div',{staticClass:"mt-3"},[(!_vm.getLoadings.get_tenant)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('TextInput',{attrs:{"name":"","type":"number","label":"Gross monthly income","aria-describedby":ariaDescribedby,"masked":true,"mask":[
                {
                  mask: 'num',
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: '.',
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      //radix: ',',
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],"defaultValue":"","prepend":"€"},model:{value:(_vm.form.personalMonthlyIncome),callback:function ($$v) {_vm.$set(_vm.form, "personalMonthlyIncome", $$v)},expression:"form.personalMonthlyIncome"}})]}}],null,false,2623266381)}):_vm._e()],1):_vm._e(),(_vm.getErrors.tenant)?_c('div',{staticClass:"alert alert-danger mb-3"},[_vm._v(" "+_vm._s(_vm.getErrors.tenant)+" ")]):_vm._e(),_c('div',{staticClass:"w-100"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"disabled":!(_vm.form && _vm.form.personalMonthlyIncome) || _vm.getLoadings.tenant},on:{"click":_vm.onSubmit}},[(_vm.getLoadings.tenant)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Spinning","variant":"white"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("Next step"))+" ")],1)])])])]},proxy:true},{key:"right",fn:function(){return [_c('RightSection',[_c('div',{staticClass:"step__rightSection mx-auto"},[_c('RightPoints',{attrs:{"active":"5"}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }